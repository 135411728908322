const md5 = require('md5');
const Config = {
  betacode: 'TERU3188',
  api_url_base: 'https://backend.prd.tel-chan.com',
  api_url_base2: 'https://api.prd.tel-chan.com',
  cancel_url: 'http://localhost:3000/signup?errorCode=2',
  slack_url: 'https://backend.prd.tel-chan.com/slack',
  gmo_shop_id: '9200007526133',
  serviceName: 'てるちゃん',
  serviceNameSubscribe: 'てるちゃん新規登録',
  docomoTimeout: 1200,
  //インボイス対応
  companyName: '株式会社ＫＤＤＩウェブコミュニケーションズ',
  companyNumber: 'T5010001065739',
  ostiariesMode: 'asp',
  ostiariesServiceId: 'KGIF7llaKMpXkDTf5WmgCLWy48rQwkQ8K0WDQIkG'
};
export{Config};

import React from "react";
import UnauthorizedHeader from "../../partials/UnauthorizedHeader";
import Footer from "../../partials/Footer";
import Text from "../../partials/Text";
import Wrap from "../../partials/Wrap";
import styles from "./index.module.sass";
function Terms(props) {
  return (
    <>
      <UnauthorizedHeader />
      <Wrap>
        <Text variant="h2">てるちゃん利用規約</Text>

        <div className={styles.heading}>第1章 総則</div>
        <div className={styles.heading2}>第1条（目的）</div>
        <Text gutterBottom>
          本規約は、当社が提供する農業IoTサービス「てるちゃん」の利用を目的とする契約の内容等について定めます。
        </Text>

        <div className={styles.heading2}>第2条（定義）</div>
        <Text gutterBottom>
          本規約で使用する用語の意義は、特段の明記のない限り以下の記載に従うものとします。
        </Text>
        <ul className={styles.list}>
          <li>
            「当社」とは、株式会社ファーストビットをいいます。
          </li>
          <li>
            「利用希望者」とは、てるちゃんの利用を希望し、当社への申込みを行う者をいいます。
          </li>
          <li>
            「本利用契約」とは、当社と利用希望者との間で成立したてるちゃんについての利用契約をいいます。
          </li>
          <li>
            「利用者」とは、本規約に同意のうえ、当社の定める手続きによりてるちゃんへの登録を申し込み、当社が承諾した者をいいます。なお、法人名を登録した場合、原則として本規約に基づく一切の権利義務および法的地位はその法人に帰属するものとします。
          </li>
          <li>
            「委託先」とは、本規約において当社が負う義務と同等の秘密保持義務等を課された、てるちゃんを運営するにあたり必要な業務に従事する第三者をいいます。
          </li>
          <li>
            「てるちゃん機器等」とは、てるちゃんを利用するために必要なネットワーク機器として当社が指定するルーター、センサー、ゲートウェイ等の機器およびてるちゃんを利用するにあたり必要なソフトウェア等をいいます。
          </li>
          <li>
            「てるちゃん」とは、てるちゃん単体またはてるちゃん機器等を含めた総称をいいます。
          </li>
          <li>
            「ID等」とは、当社が利用者に対し発行するIDおよびパスワードをいいます。
          </li>
          <li>
            「個人情報」とは、氏名、住所、電話番号、メールアドレス等、特定の個人に関する情報で、その中に当該個人を特定しうる情報を含むものをいいます。また、当該個人を特定しうる情報には、単独では当該個人を特定できなくても、将来収集する情報または既に収集した情報を組み合わせることにより、当該個人を特定できる情報、および通信履歴を含みます。なお、機密情報であるかどうかを問いません。
          </li>
          <li>
            「知的財産権」とは、特許権、実用新案権、意匠権、商標権ならびにこれらの設定登録を受ける権利および著作権ならびに不正競争防止法において定める権利その他の知的財産・知的財産権の総称をいいます。
          </li>
        </ul>

        <div className={styles.heading2}>第3条（前提）</div>
        <ul className={styles.list}>
          <li>
            利用希望者は、本規約および当社が定めるプライバシーポリシーの内容を確認し、理解し、同意したうえで、てるちゃんの利用を申込むことができます。
          </li>
        </ul>

        <div className={styles.heading2}>第4条（本利用契約の成立）</div>
        <ul className={styles.list}>
          <li>
            本利用契約は、利用希望者が本規約に同意の上、当社に対し、てるちゃんの利用を申し込み、当社が当該利用希望者に対し承諾する旨を通知（電子メールを含む。）した時点において成立するものとします。
          </li>
          <li>
            当社は、利用希望者が下記に掲げる事項に該当し、またはそのおそれがあると判断する場合には、利用契約の申込みを承諾せず、または本利用契約を遡って無効とする場合があります。
            <ul className={styles.list}>
              <li>
                申込み内容に虚偽、遺漏、誤記等がある、またはそのおそれがある場合
              </li>
              <li>
                当社の定める申込手続きを行わず、または行わないおそれがある場合
              </li>
              <li>
                申込者が反社会勢力であるまたはこれらとの実質的な関係にあると判明している場合
              </li>
              <li>申込み住所がてるちゃんの提供地域外の場合</li>
              <li>
                申込み時点において、本規約に反したことがある、または反するおそれのある場合
              </li>
              <li>
                てるちゃんに競合するおそれのあるサービスを提供している、またはその予定が明らかである場合
              </li>
              <li>その他当社が不適当と判断する場合</li>
            </ul>
          </li>
        </ul>

        <div className={styles.heading2}>第5条（本規約の変更）</div>
        <ul className={styles.list}>
          <li>
            本規約は、利用者がてるちゃんを利用するにあたって適用される条件を定めるものであって、当社の通知（電子メール、書面、ウェブサイトへの掲載を含み、当社が適当と判断する方法によります。）により、随時変更されるものとします。
          </li>
          <li>
            利用者が変更後にてるちゃんを利用したことをもって、変更後の本規約に同意したものとみなします。
          </li>
          <li>
            当社および利用者は、本規約の変更後は、変更後の本規約の内容に従っててるちゃんを提供し、利用するものとします。
          </li>
          <li>
            変更後の本規約は、てるちゃんのウェブサイト上で閲覧することができます。
          </li>
        </ul>

        <div className={styles.heading2}>第6条（有効期間）</div>
        <Text gutterBottom>
          本利用契約の有効期間は、本利用契約が成立した日から本利用契約が終了する日までとします。
        </Text>

        <div className={styles.heading2}>第7条（中途解約の手続き）</div>
        <Text gutterBottom>
          本利用契約の有効期間中に、利用者が本利用契約を解約することを希望する場合は、書面、メール、てるちゃんまたはてるちゃんのウェブサイト上の手続きにより、当社に対し解約を申し入れ、当社所定の解約手続を行うものとします。
        </Text>

        <div className={styles.heading2}>第8条（解除）</div>
        <Text gutterBottom>
          当社は、利用者が次の各号のいずれかに該当する場合、利用者に対し何ら催告等することなく、利用者との間の本利用契約を解除し、又は強制的に利用を停止することができるものとします。
        </Text>
        <ul className={styles.list}>
          <li>
            本規約、当社が別途定めるてるちゃん機器等の利用の条件、法令または公序良俗に違反した場合
          </li>
          <li>
            振出、引受、裏書または保証を行った手形若しくは小切手が不渡りとなった場合
          </li>
          <li>
            その財産について強制執行若しくは担保実行等の申し立てを受けた場合、またはその重要な財産について仮差押若しくは仮処分等を受けた場合
          </li>
          <li>
            破産手続開始、民事再生手続開始、更生手続開始、特別清算開始またはこれらに類する法的倒産手続開始の申し立てがあった場合
          </li>
          <li>
            営業の廃止若しくは変更または営業の全部若しくは重要な一部の第三者への譲渡を決議または実行した場合
          </li>
          <li>公租公課等の滞納処分を受けた場合</li>
          <li>解散を決議した場合、解散命令または解散判決を受けた場合</li>
          <li>営業登録等の取り消しまたは営業停止の処分を受けた場合</li>
          <li>利用者またはその代表者の所在が不明となった場合</li>
          <li>
            利用者が本規約に関連して違法行為その他の不正な行為を行った場合
          </li>
          <li>
            反社会的勢力に属することまたは反社会的勢力と関わりがあることが判明した場合
          </li>
          <li>
            前各号の他、当社が、利用者によるてるちゃんの利用の継続が不適当と判断する場合
          </li>
        </ul>

        <div className={styles.heading2}>第9条（終了後の措置）</div>
        <ul className={styles.list}>
          <li>
            利用者は、解約、解除その他理由の如何を問わずてるちゃんが終了した場合には、いかなる理由においてもてるちゃんを利用することはできません。
          </li>
          <li>
            前項の場合、利用者が管理していた情報は、その終了の日から3か月の経過をもって全て消去されるものとします。ただし、てるちゃんを利用して利用者が投稿したコンテンツについては、その一部を分析し、当社の判断により利用する場合があります。
          </li>
          <li>
            利用者は、解約、解除その他理由の如何を問わず、その終了の日までの期間に相当するてるちゃんの利用料を、当社からの請求に基づき支払うものとします。なお、この計算の方法は第15条（利用料および支払条件）の規定に、利用者がこの支払いを延滞等した場合の取扱いは、第16条（遅延損害金）の規定にそれぞれ準ずるものとします。
          </li>
        </ul>

        <div className={styles.heading}>第2章 てるちゃん機器等</div>

        <div className={styles.heading2}>第10条（てるちゃん機器等の購入）</div>
        <ul className={styles.list}>
          <li>
            利用者は、てるちゃんの利用にあたって、当社の定める手続きに従い、登録後速やかにてるちゃん機器等の購入を申し込むものとします。
          </li>
          <li>
            利用者と当社との間にてるちゃん機器等に関する売買契約は、前項の規定に従い利用者による申込みがなされ、その後に当社が利用者に対し当該申込みを承諾する旨の通知を行った時点で成立するものとします。なお、売買契約の取扱いについては、第4条（本利用契約の成立）の規定を読み替えて準用します。
          </li>
          <li>
            てるちゃん機器等の利用に関し不正または不適当な行為があった場合の取扱いについては、本規約に定める規定を準用します。
          </li>
          <li>
            利用者は、第1項の規定に基づく申込み後、その申込み内容の変更または撤回を希望する場合は、速やかにその旨を当社に対し電子メールその他当社の定める手段により通知するものとします。なお、原則として、売買契約の成立後において、当社の責めに帰すべき事由のない限り、売買契約の内容の変更または撤回は認められないものとします。
          </li>
          <li>
            てるちゃん機器等の購入金額の支払いについては、第15条（利用料および支払条件）の規定が適用されるものとします。
          </li>
        </ul>

        <div className={styles.heading2}>第11条（引渡しおよび返品・交換）</div>
        <ul className={styles.list}>
          <li>
            当社または委託先は、利用者によるてるちゃん機器等の購入金額の支払いを確認した後、てるちゃん機器等を発送します。
          </li>
          <li>
            てるちゃん機器等に関する危険負担は、当社または委託先がてるちゃん機器等を配送業者に引渡した時点で、利用者に移転するものとします。
          </li>
          <li>
            利用者は、てるちゃん機器等の受取り後、「特定商取引法に基づく表記」等に記載する期間内に、てるちゃん機器等の動作その他の状態を確認するものとし、なんらかの異状が認められる場合にあっては、当社に対し、電子メール等の当社が別途定める手段でその旨を通知するものとします。なお、利用者による当社に対するなんらの通知もなされない場合、当該期間の経過をもって、てるちゃん機器等の所有権は利用者に移転するものとします。
          </li>
          <li>
            てるちゃん機器等の発送後、利用者の責めに帰すべき事由により、配送業者が定める期日内にてるちゃん機器等の受取りがなされなかった場合、当社は、当該てるちゃん機器等に関する売買契約を事前の催告なく解除し、てるちゃん機器等を回収することができるものとします。
          </li>
          <li>
            第1項に記載のてるちゃん機器等の発送日数、第3項に記載の通知を行った後の返品または交換その他の取扱いは、「特定商取引法に基づく表記」等に記載するとおりとします。
          </li>
        </ul>

        <div className={styles.heading}>第3章 サービスの内容およびその改廃</div>

        <div className={styles.heading2}>第12条（てるちゃんの利用）</div>
        <ul className={styles.list}>
          <li>
            てるちゃんの利用にあたっては、本規約に同意の上、てるちゃんのウェブサイトにウェブブラウザーにてアクセスするか、または当社が提供する専用アプリケーションを利用する必要があります。
          </li>
          <li>
            利用者は、ウェブブラウザー、OS、ソフトウェアおよびアプリケーションの種類、バージョン等によっては、てるちゃんの全部または一部を利用することができない場合があることを認識し、同意します。
          </li>
          <li>
            てるちゃんの利用にあたって利用者において発生する通信利用料その他一切の費用は、利用者が負担するものとします。
          </li>
        </ul>

        <div className={styles.heading2}>第13条（使用の許諾）</div>
        <ul className={styles.list}>
          <li>
            当社は、利用者が本規約を遵守することを条件として、利用者に対して、非独占的かつ譲渡・再許諾不能の日本国内におけるてるちゃんの使用権を許諾します。
          </li>
          <li>
            利用者は、利用者自身の環境情報や付随するコンテンツを利活用する目的に限って、てるちゃんを利用することができるものとし、当該目的の範囲を超えて利用してはならないものとします。
          </li>
          <li>
            第1項の規定にかかわらず、利用者は、一定の制限を受けたてるちゃんの使用権（以下「閲覧権等」といいます。）を第三者に再許諾することができます。この場合においても、その再許諾を受けた者（以下「被再許諾者」といいます。）による利用は、第2項に規定する利用者のための利活用の目的に限られるものとします。
          </li>
          <li>
            利用者は、被再許諾者に対し、本規約において自己が負うものと同等の義務を課し、これを遵守させ、当該被再許諾者の行為によって生じた損害を賠償する責を負うものとします。
          </li>
          <li>
            本条における利用者から被再許諾者への閲覧権等の再許諾は、当社から利用者に対し、第三者へのてるちゃんの再販、第2項に規定する利用者のための利活用の目的以外での利用、その他当社が本規約において禁止する行為を許諾するものではなく、利用者は、被再許諾者の行為がこれに反すると認識した場合には、即座にその利用を停止させ、または当社がその利用を停止させることに同意するものとします。
          </li>
        </ul>

        <div className={styles.heading2}>第14条（ID等の取扱いについて）</div>
        <ul className={styles.list}>
          <li>
            利用者は、ID等を、全て自己の責任において使用および管理するものとし、当社の事前の書面による許諾のない第三者に対し開示・共有・漏洩等しないものとし、これらの漏洩等を防ぐため、必要な措置を適宜講じるものとします。
          </li>
          <li>
            利用者は、自己のID等が不正に第三者に使用され、または当社の事前の書面による許諾なく開示・共有・漏洩等していることを認識した場合には、速やかに当社に対しその旨を書面またはメールを用いて通知し、当社からの指示があった場合には、これに従うものとします。
          </li>
          <li>
            利用者は、ID等を、善良な管理者の注意をもって適切に管理します。当該ID等が利用者以外の者によって入力され、てるちゃんを操作されたときであっても、その入力は利用者本人によるものであるとみなされ、利用者の事前の許諾の如何にかかわらず、当該ID等を用いて行われたすべての結果および責任は、利用者に帰属するものとします。
          </li>
          <li>
            当社は、ID等に関し、前項までに掲げる事由その他不正使用等によって利用者に何らかの損害が生じた場合においても、一切責任を負いません。
          </li>
        </ul>

        <div className={styles.heading2}>第15条（利用料および支払条件）</div>
        <Text gutterBottom>
          利用者は、当社が定めるてるちゃんの利用料（てるちゃん機器等に関する購入金額を含みます。）を、別に定める支払期限その他の支払条件に基づき支払うものとします。
        </Text>

        <div className={styles.heading2}>第16条（遅延損害金）</div>
        <ul className={styles.list}>
          <li>
            当社は、利用者が前条の料金の支払いを遅延した場合、利用者に対して、支払期日の翌日より完済の前日まで年利14.6％の遅延損害金を請求することができます。ただし、法律で許容される最大の利率がこれを下回る場合、当該法律の定めに従うものとします。
          </li>
          <li>
            利用者がてるちゃんの利用料を支払期限までに支払わないことにより、当社が催告その他の手続き等に要した費用については、利用者が負担するものとします。
          </li>
        </ul>

        <div className={styles.heading2}>第17条（てるちゃんの中止等）</div>
        <ul className={styles.list}>
          <li>
            当社は、次の各号のいずれかに該当する場合、利用者に何ら通知することなく、てるちゃんの全部または一部について、停止または中止することができるものとします。
            <ul className={styles.list}>
              <li>利用者が本規約に違反した場合</li>
              <li>利用者がてるちゃんの利用料その他の債務を支払わない場合</li>
              <li>
                てるちゃんを提供するために必要な設備・システムの保守点検・更新を定期的または緊急に実施する場合
              </li>
              <li>
                火災、停電、天災、労働争議、戦争、テロ、暴動その他不可抗力または第三者による妨害等その他の非常事態が発生し、または発生するおそれがあり、てるちゃんの提供が困難である場合
              </li>
              <li>その他当社が必要と判断する場合</li>
            </ul>
          </li>
          <li>
            当社は、前項の規定に基づきてるちゃんの全部または一部を停止または中止した場合であっても、利用者に対し補償・填補その他の一切の賠償を行う義務を負わないものとします。
          </li>
        </ul>

        <div className={styles.heading2}>第18条（てるちゃんの廃止）</div>
        <ul className={styles.list}>
          <li>
            本規約の規定にかかわらず、当社は、てるちゃん廃止の1か月前までに利用者にてるちゃんを廃止する旨を通知（電子メール、書面、ウェブサイトへの掲載を含み、当社が適当と判断する方法によります。）することにより、てるちゃんを廃止することができるものとします。この場合、てるちゃんが終了した時点において、本利用契約も当然に終了するものとします。
          </li>
          <li>
            前項の規定に基づきてるちゃんを廃止した場合の取扱いについては、第17条（てるちゃんの中止等）第2項の規定に準ずるものとします。
          </li>
        </ul>

        <div className={styles.heading}>第4章 サービス利用に伴う責務</div>

        <div className={styles.heading2}>第19条（禁止事項）</div>
        <ul className={styles.list}>
          <li>
            利用者は、てるちゃんの利用にあたり、次の各号のいずれかに該当する行為または該当するおそれのある行為を行ってはなりません。ただし、当社の事前の書面による許諾がある場合には、この限りではありません。
            <Text gutterBottom gutterTop>
              (1) 権利侵害に関する禁止事項
            </Text>
            <ul className={styles.list}>
              <li>
                当社または第三者の知的財産権または財産的利益を侵害するおそれのある行為をし、または侵害すること
              </li>
              <li>
                プライバシー権や肖像権その他の人格的権利を侵害するおそれのある行為をし、または侵害すること
              </li>
              <li>他者になりすましててるちゃんを利用すること</li>
              <li>
                当社の事前の書面または電子メールによる許諾なくてるちゃんを第三者に貸与、譲渡、使用許諾その他の処分をすること
              </li>
            </ul>
            <Text gutterBottom gutterTop>
              (2) 違法な行為等に関する禁止事項
            </Text>
            <ul className={styles.list}>
              <li>
                日本、米国その他の国における輸出管理および経済制裁に関する法令等に違反しててるちゃんを利用すること
              </li>
              <li>
                前各号の他法律、条例、その他の諸法令、諸規則、または公序良俗に反すること
              </li>
            </ul>
            <Text gutterBottom gutterTop>
              (3) 技術的な理由による禁止事項
            </Text>
            <ul className={styles.list}>
              <li>
                てるちゃんに対して改変、変更、改修、リバースエンジニアリング、分解、デアセンブル、デコンパイル等をすること、またはてるちゃんから得られるソースコードやアプリケーション等を、第4条に定める範囲を超えて、当社の書面による承諾なしに無断で使用すること
              </li>
              <li>
                てるちゃんを利用して緊急通報用電話番号や類似の緊急サービスにアクセスすること
              </li>
              <li>
                法律または行政等が規制する公衆電話網の利用方法に従わないこと
              </li>
              <li>
                てるちゃんにおいて当社が提供する電話番号から発信されたSMSを、他の電気通信事業者に直接リレーすること
              </li>
              <li>
                てるちゃんにおけるSMSを、不特定多数の者に対するマーケティングや広告等の目的に利用すること
              </li>
              <li>
                当社の設備や第三者のネットワーク、サーバー等に悪影響を与える一切のプログラムを使用すること
              </li>
              <li>
                当社の設備に不正にアクセスし、または蓄積された情報を不正に書き換え、もしくは消去すること
              </li>
              <li>ウイルス等の有害なプログラム等を送信すること</li>
              <li>当社の設備またはてるちゃんに必要以上の負荷をかけること</li>
              <li>
                てるちゃんを利用して第三者のメールアドレスや電話番号等の情報を不当に収集すること
              </li>
            </ul>
            <Text gutterBottom>(4) その他の禁止事項</Text>
            <ul className={styles.list}>
              <li>
                当社が発行したID等を適切に管理することなく、みだりに第三者に対して公開し、利用させ、または利用できる状態におくこと
              </li>
              <li>
                いたずらまたはスパム等、てるちゃんを利用して第三者に迷惑をかけ、またはかけるおそれがあると当社が判断する行為をすること
              </li>
              <li>
                当社が定める基準を満たすことなく、てるちゃんを利用すること
              </li>
              <li>
                てるちゃんの円滑な提供に必要であると判断してなされた当社の指示に従わないこと
              </li>
              <li>上記に掲げるほか、当社が不適当と判断する行為をすること</li>
            </ul>
          </li>
        </ul>

        <div className={styles.heading2}>第20条（損害賠償）</div>
        <Text gutterBottom>
          利用者は、利用者の責めに帰すべき事由により当社に損害を与えた場合、当社に対し、損害（裁判費用および弁護士報酬を含みますが、これらに限りません。）を賠償しなければならないものとします。
        </Text>

        <div className={styles.heading2}>第21条（利用者の違反による賠償）</div>
        <Text gutterBottom>
          利用者が第19条（禁止事項）に掲げる禁止事項その他本規約に違反することにより、第三者から当社に対して何らかのクレーム、請求、抗議等（以下「請求等」という）がなされた場合、利用者はかかる請求等を自らの責任で解決するものとし、万一かかる請求等に基づき当社に損害または弁護士費用その他の支出等が発生した場合には、利用者は、当社に対してその損害および支出等を賠償しなければなりません。
        </Text>

        <div className={styles.heading2}>第22条（非保証）</div>
        <ul className={styles.list}>
          <li>
            当社は、てるちゃんに関する動作の完全性、安全性、有用性、正確性、通信の安定性、データ保全性、その他の瑕疵の不存在等を、明示または黙示にも保証するものではありません。利用者は、てるちゃんが、現状有姿で提供されるものであって、当社からの保証を受けられない場合があることを認識し、これに同意します。
          </li>
        </ul>

        <div className={styles.heading2}>第23条（免責）</div>
        <ul className={styles.list}>
          <li>
            てるちゃんの変更、停止、中止若しくは廃止、利用者情報の変更、削除若しくは消滅、その他てるちゃんに関連して発生した利用者の損害（逸失利益を含みますが、これに限られません。）について、当社は賠償する責任を一切負わないものとします。ただし、当社の故意または重過失により生じた事由については、この限りではありません。
          </li>
          <li>
            当社は、アクセス過多、その他予期せぬ要因に基づくてるちゃんの表示速度（利用者に対する通知の速度を含みますがこれに限られません。）の低下や障害等によって生じたいかなる損害および不正なアクセス等による情報流出による損害について、当社の故意または重過失により生じた事由を除き、責任を負いません。
          </li>
          <li>
            当社は、てるちゃん機器等の故障その他の理由においても、そのデータが滅失・破損あるいはなんらかの障害等により現実の値と異なるデータが提供される等の理由により生じた損害について、一切の責任を負わないものとします。ただし、当社の故意または重過失により生じた事由については、この限りではありません。
          </li>
          <li>
            てるちゃんは、可能な限り多くの農家の方々を広くサポートするために開発および提供される農業IoTサービスであり、当社はこの使命を果たすため、可能な限り低廉な価格でてるちゃんを提供するよう努めています。このことから、利用者が当社から受けられる補償は、消費者契約法ないし民法における信義則その他の法令等において免れることのできないものに限られます。
          </li>
          <li>
            利用者は、てるちゃんを利用する際には本条に記載の事項をあらかじめ認識し、営業上または事業上、必ずの確認等が必要となる場合にあっては、自らの費用と責任においててるちゃん以外の手段または方法を確保し、自己の農作物その他の営業または事業全体の安全を自ら確保する義務を負うものとします。
          </li>
          <li>
            利用者は、自己の責任においててるちゃん機器等の品質維持・確認・メンテナンス等を行う義務を負い、これを遵守するものとします。
          </li>
        </ul>

        <div className={styles.heading2}>第24条（第三者に対する責任）</div>
        <ul className={styles.list}>
          <li>
            利用者は、本規約、てるちゃんに関し、第三者に対し損害を与えた場合、または第三者との間で紛争が生じた場合、利用者の費用と責任において当社を免責し、これを解決し、損害を賠償する責任を負うものとします。ただし、当該紛争が当社の故意または重過失により発生したものである場合は、この限りではありません。
          </li>
          <li>
            前項の場合、当社が第三者に対し、何らかの費用（賠償金、和解金、裁判費用および弁護士報酬等を含みますが、これらに限られません。）を負担した場合は、利用者は当社に対し、その費用の全額を賠償しなければならないものとします。
          </li>
        </ul>

        <div className={styles.heading}>
          第5章 てるちゃんにかかる情報の取扱い
        </div>

        <div className={styles.heading2}>第25条（知的財産権の帰属）</div>
        <ul className={styles.list}>
          <li>
            てるちゃんには、当社または当社に権利を許諾した第三者が権利を有する知的財産権が含まれており、これに関する権利は、当社または当社に権利を許諾した第三者に帰属します。当社および第三者は、てるちゃんに関する知的財産権、営業秘密ならびにその他すべての権利およびこれに関連する標章、商標その他すべての権利を留保します。
          </li>
        </ul>

        <div className={styles.heading2}>第26条（利用者固有情報の取扱い）</div>
        <ul className={styles.list}>
          <li>
            前条の規定にかかわらず、てるちゃんを利用して、利用者が計測する環境情報および投稿したコンテンツ（以下「利用者固有情報」といいます。）については、利用者に権利が帰属します。
          </li>
          <li>
            当社は、利用者の利便性を高めるために重要な機能および製品を紹介する目的で、利用者固有情報を分析することがあります。
          </li>
          <li>
            利用者は、利用者固有情報について、当社に対し非独占的にいかなる制約も受けずに使用する権利を許諾します。当社は、利用者固有情報を、以下の用途で利用する場合があります。
            <ul className={styles.list}>
              <li>てるちゃんの改善、改修、拡販</li>
              <li>
                てるちゃんまたはこれに代わる、あるいは類似するサービスの開発および研究
              </li>
              <li>
                農業および産業の発達に寄与するために他社と共同で行う開発および研究
              </li>
              <li>
                農業および産業の発達に寄与するために行う他社への情報の譲渡、提供および交換
              </li>
              <li>当社サービスの販売および利用の促進</li>
            </ul>
          </li>
        </ul>

        <div className={styles.heading2}>第27条（利用者情報の取扱い）</div>
        <ul className={styles.list}>
          <li>
            当社は、てるちゃんを提供するにあたって、利用者（従業員を含みます。）の個人情報、その他の利用者より提供される情報（以下「利用者情報」と総称します。）を取得します。
          </li>
          <li>
            当社は、利用者情報を、当社が定めるプライバシーポリシーに従い取り扱います。利用者は、事前にプライバシーポリシーの内容を確認し、同意の上でてるちゃんを利用するものとします。
          </li>
          <li>
            当社は、てるちゃんの提供にあたり、利用者情報の送出が必要であるにもかかわらず、利用者の同意が得られない場合は、てるちゃんの利用を制限する場合、または提供を見合わせる場合があります。
          </li>
          <li>
            利用者は、本利用契約の情報に関して、利用者に何ら事前に通知することなく、当社とてるちゃんの販売取次会社との間で必要に応じ共有されることについて、同意するものとします。
          </li>
        </ul>

        <div className={styles.heading2}>第28条（秘密保持義務）</div>
        <ul className={styles.list}>
          <li>
            当社および利用者は、てるちゃんを提供または利用するために必要な相手方の技術上または営業上の情報（以下「秘密情報」といいます。）を、善良な管理者の注意をもって管理します。ただし、以下に掲げる事由に該当する場合は、その情報は、秘密情報として取扱わないものとします。
            <ul className={styles.list}>
              <li>開示の時にすでに公知の情報</li>
              <li>開示の時にすでに保有していた情報</li>
              <li>
                開示の後、開示を受けた側の責めに帰すべき事由によらずに公知になった情報
              </li>
              <li>第三者から適法に知得した情報</li>
              <li>
                開示を受けた側が、開示された情報に依らずに独自に開発または発見したことを書面により証明できる情報
              </li>
            </ul>
          </li>
          <li>
            当社および利用者は、秘密情報を、てるちゃんの提供または利用のためにのみ利用するものとし、必要な範囲を超えて、秘密情報を複写、複製または改変してはならないものとします。
          </li>
          <li>
            当社および利用者は、秘密情報を、最低限必要のある自己の役員または従業員のみに開示するものとし、相手方の事前の書面による許諾のある場合を除き、第三者に対し秘密情報を開示し、漏洩してはならないものとします。
          </li>
          <li>
            前項の規定にかかわらず、当社および利用者は、行政、司法その他の公的機関等により、法令、条例、規則等に基づく秘密情報の開示を請求または要請された場合、当該秘密情報を、その範囲において開示できるものとします。ただし、この場合において当該開示を行う当事者は、相手方に対しその事実を速やかに通知し、その開示の範囲について、みだりに広範にならないよう努力するものとします。
          </li>
        </ul>

        <div className={styles.heading2}>第29条（委託）</div>
        <Text gutterBottom>
          当社は、てるちゃんの提供に関する業務の全部または一部を、委託先に委託することができます。
        </Text>

        <div className={styles.heading}>第6章 その他</div>

        <div className={styles.heading2}>第30条（反社会的勢力の排除）</div>
        <Text gutterBottom>
          利用者は、次の各号に定める事項を現在および将来にわたって表明し、保証するものとします。
        </Text>
        <ul className={styles.list}>
          <li>
            自らが暴力団（暴力団員による不当な行為の防止等に関する法律（以下｢暴排法｣といいます。）第2条第2号に規定する暴力団をいう。）、暴力団員（同条第6号に規定する暴力団員）、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標榜ゴロまたは特殊知能暴力集団その他暴力、威力、詐欺的手法を用いて暴力的不法行為等（同条第1号に規定する行為）を常習的に行う、または自らの目的を達成することを常習とする集団または個人（以下「反社会的勢力」と総称します。）に該当しないこと
          </li>
          <li>
            自己の代表者、役員または主要な職員（雇用形態および契約形態を問いません。）が反社会的勢力に該当しないこと
          </li>
          <li>
            直接、間接を問わず、反社会的勢力が自己の経営に関与していないこと
          </li>
          <li>
            自己若しくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもってするなど、不当に反社会的勢力を利用していると認められる関係を有していないこと
          </li>
          <li>
            反社会的勢力に対して資金等の提供ないし便宜の供与等をしていないこと
          </li>
          <li>
            自己の代表者、役員または経営に実質的に関与している者が反社会的勢力と社会的に非難されるべき関係を有していないこと
          </li>
        </ul>

        <div className={styles.heading2}>第31条（存続条項）</div>
        <Text gutterBottom>
          本利用契約の終了後においても、第9条（終了後の措置）、第18条（損害賠償）、第21条（利用者の違反による賠償）、第22条（非保証）第23条（免責事項）、第24条（第三者に対する責任）、本条、第32条（完全合意）、第33条（分離可能性）、第34条（準拠法・裁判管轄）の規定は引き続き有効に存続します。
        </Text>

        <div className={styles.heading2}>第32条（完全合意）</div>
        <Text gutterBottom>
          本規約は、当社と利用者との間での完全なる合意を形成するものとし、本規約に基づいた利用契約の成立の前後を問わず、当事者全員の署名または記名・押印がされた契約その他の書面による合意を除き、他の合意によりなんらの影響も受けないものとします。
        </Text>

        <div className={styles.heading2}>第33条（分離可能性）</div>
        <Text gutterBottom>
          本規約について、いずれかの条項またはその一部が消費者契約法その他の法令により無効または執行不能と判断された場合であっても、本規約の残余の条項はその有効性に影響がないものとし、当該無効となる事項については、その取扱いについて当事者間で別途協議するものとします。
        </Text>

        <div className={styles.heading2}>第34条（準拠法・裁判管轄）</div>
        <ul className={styles.list}>
          <li>本規約は、日本国法を準拠法とします。</li>
          <li>
            本規約、てるちゃんに関連して生じた一切の紛争については、東京簡易裁判所または東京地方裁判所を第一審の専属的合意管轄裁判所とします。
          </li>
        </ul>
      </Wrap>
      <Footer />
    </>
  );
}

export default Terms;

import React from 'react';
import UnauthorizedHeader from '../../partials/UnauthorizedHeader';
import Footer from '../../partials/Footer';
import DefinitionList from '../../partials/DefinitionList';
import Text from '../../partials/Text';
import Wrap from '../../partials/Wrap';
function Law(props) {
  return (
    <>
      <UnauthorizedHeader />
      <Wrap>
        <Text variant="h2" gutterBottom>特定商取引法に基づく表記</Text>
        <DefinitionList title="販売業社名" gutterBottom>
          株式会社ファーストビット
        </DefinitionList>
        <DefinitionList title="代表者" gutterBottom>
          安田　善之
        </DefinitionList>
        <DefinitionList title="所在地" gutterBottom>
          〒101-0048<br />
          東京都千代田区神田司町2-13 神田第4アメレックスビル
        </DefinitionList>
        <DefinitionList title="電話番号" gutterBottom>
          03-6822-7620（代表）<br />
          平日10:00～18:00（土日祝祭日は休業）
        </DefinitionList>
        <DefinitionList title="販売価格及び送料" gutterBottom>
          機器の価格は、機器の購入ページに記載しております。表示の販売価格はすべて消費税込（総額表示）です。<br />
          商品代金以外のお支払い金額：送料および発送にかかる費用は、お客様にてご負担いただきます。<br />
          送料はお届け先都道府県により変動いたします。
        </DefinitionList>
        <DefinitionList title="支払方法" gutterBottom>
          クレジットカード（Visa、MasterCard、JCB、American Express）/キャリア決済(auまとめて決済・docomo支払い)/請求書払い
        </DefinitionList>
        <DefinitionList title="支払期限" gutterBottom>
          当月ご利用分は翌月末までにお支払いください。クレジットカードをご利用の場合は、クレジットカードの締め日や契約内容により異なります。ご利用されるカード会社までお問い合わせください。<br />
          キャリア決済の場合はご利用されるキャリアまでお問い合わせください。
        </DefinitionList>
        <DefinitionList title="サービス利用開始日" gutterBottom>
          ご利用開始日は、ご購入機器がお客様のお手元に到着した日付といたします。到着した日付は、配送会社の配送記録に準じます。
        </DefinitionList>
        <DefinitionList title="商品の引渡時期等" gutterBottom>
          商品の引渡時期等：申し込みから発送までは最大10営業日かかります。発送から到着までにかかる日数は、お客様のお住まいの地域によって異なります。（営業日は機器メーカーの規定によります）
        </DefinitionList>
        <DefinitionList title="返品に関する事項（返品の可否・返品の期間等条件、返品の送料負担の有無）" gutterBottom>
          返品：お届けの商品が申込内容と異なる場合、汚損・破損・初期不良のある場合は返品をお受けいたします。<br />
                ・返品をご希望の場合は、商品到着後8日以内にお申し出ください。<br />
                ・当社責任の返品や交換にかかる送料は、当社にて負担いたします。<br />
                ・解約条件はウェブサイトより解約ボタンを押すこと、もしくは一定条件の未入金等の弊社が取り決める規約に反した場合です。
        </DefinitionList>
        <DefinitionList title="商品の隠れた瑕疵に関する販売業者の責任" gutterBottom>
          納入から1年以内にお客さまから連絡があった場合は、修理又は代替物との交換を行います。
        </DefinitionList>
        <DefinitionList title="解約および一時休止の条件/方法" gutterBottom>
          解約の場合は、解約をお申し出いただき、当社所定の解約手続きにしたがいます。<br />
          もしくは、一定条件の未入金等、弊社が取り決める規約に反した場合は解約となります。<br />
          一時休止の場合は、ルーター（親機）およびセンサー（子機）に対し稼働停止設定を行い、休止中はルーター1台につき月額330円、休止中センサー1台につき月額0円として請求いたします。<br />
          なお、月途中の解約および一時休止については、システム利用料金は日割り計算せずに同月末までの１ヶ月分を請求いたします。監視料金、通知料金、確認料金は、解約または一時休止までのご利用分を清算して請求いたします。
        </DefinitionList>
        <DefinitionList title="ソフトウェアを使用するための動作環境" gutterBottom>
          スマートフォン推奨、OS/ブラウザは以下を推奨。<br />
          推奨OS：iOS 11.0以降,Android OS 5.0以降<br />
          推奨ブラウザ：iOS Safari（最新版）,Android Chrome（最新版）<br />
        </DefinitionList>
      </Wrap>
      <Footer />
    </>
  );
}

export default Law;

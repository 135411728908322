import { makeStyles } from '@material-ui/core/styles';
import { A } from 'hookrouter';
import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Config } from '../config';
import Button from "../partials/Button";
import Checkbox from "../partials/Checkbox";
import { CheckUser } from '../partials/checkUser';
import Input from "../partials/Input";
import Text from "../partials/Text";
import { ValidateUser } from '../partials/validateUser';
import Wrap from "../partials/Wrap";
import { Telchan } from '../Telchan';
import styles from './Top.module.sass';

export const useDidMount = (func) => useEffect(() => { func() }, []);
const BETACODE = 'TERU3188';

const useStyles = makeStyles(theme => ({
}));

function Top(props) {
  const classes = useStyles();
  const [init, setInit] = useState(false);
  const [betaCode, setBetaCode] = useState(Config.betacode);
  const [tmpBetaCode, setTmpBetaCode] = useState('');
  const [showBetaAlert, setShowBetaAlert] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [canEnter, setCanEnter] = useState(false);
  const [isRevealingPassword, setIsRevealingPassword] = useState(false);

  const onload = async () => {
    if (Telchan.env != 'development') {
      if (window.location.href.match(/^http:\/\/localhost:[\d]*\//)) {
        setCanEnter(true);
      }
    }
    const user = await CheckUser(props.Auth);
    if (user) {
      const url = new URL(window.location.href);
      setTimeout(() => {
        window.location.href = url.protocol + '//' + url.host + '/dashboard';
      }, 300);
    } else {
      setInit(true);
    }
  };

  useDidMount(onload);

  const authBeta = () => {
    setShowBetaAlert(false);
    if (tmpBetaCode === BETACODE) {
      setBetaCode(BETACODE);
    } else {
      setShowBetaAlert(true);
    }
  };

  const login = async () => {
    setShowAlert(false);
    
    if (ValidateUser.validateEmail(username).length > 0) {
        setAlertMessage('上段にはメールアドレスをご入力ください');
        setShowAlert(true);
        return;
    }
    
    try {
      const user = await props.Auth.signIn(username, password);
      //navigate('/dashboard', false);
      setTimeout(() => {
        const url = new URL(window.location.href);
        const query = new URLSearchParams(url.searchParams);
        const token = query.get('token')
        const mail = query.get('mail')
        if (token) {
          window.location.href = url.protocol + '//' + url.host + '/dashboard?token=' + token;
        } else if (mail) {
          window.location.href = url.protocol + '//' + url.host + '/dashboard?mail=' + mail;
        } else {
          window.location.href = url.protocol + '//' + url.host + '/dashboard';
        }
      }, 300);
    } catch(e) {
      setAlertMessage('ログインできませんでした');
      setShowAlert(true);
    }
  }

  if (!init) {
    return <div />
  }

  if (!betaCode) {
    return (
    <>
      <Wrap>
        <Text variant="h1" center>ベータ版のご案内</Text>
        <Text variant="h3" center gutterBottom>β版コードを入力してください</Text>
        <Input 
          maxLength={BETACODE.length}
          type="text"
          onChange={(e) => {setTmpBetaCode(e.target.value);}}
          value={tmpBetaCode}
        />
        {showBetaAlert &&
          <Alert style={{borderRadius:0}} variant="danger">β版コードが違います</Alert>
        }
        <div className={styles.action}>
          <Button
            primary
            fit
            onClick={authBeta} 
          >
            次に進む
          </Button>
        </div>
      </Wrap>
    </>
    );
  }

  return (
    <>
      <Wrap>
        <div className={styles.headerLogo}>
          <header className={styles.topLogo}>
            <img src="/assets/images/top_logo.svg" alt="logo" width="256" />
          </header>
          <div>
            <div className={styles.field}>
              <Input
                onChange={(e) => {setUsername(e.target.value);}}
                value={username}
                type="email"
                placeholder="メールアドレス"
                size="large"
              />
            </div>
            <div className={styles.field}>
              <Input
                onChange={(e) => {setPassword(e.target.value);}}
                value={password}
                type={isRevealingPassword ? 'text' : 'password'}
                placeholder="パスワード"
                size="large"
              />
              <div className={styles.checkboxWrap}>
                <Checkbox
                  checked={isRevealingPassword}
                  label="パスワードを表示"
                  onClick={(e) => {setIsRevealingPassword((prevState) => !prevState)}}
                />
              </div>
            </div>
            {showAlert &&
              <Alert style={{borderRadius:0}} variant="danger">{alertMessage}
              </Alert>
            }
            <div className={styles.action}>
              <Button onClick={login}
              fit primary>ログイン</Button>
            </div>
            <div>
              <A href="/password_reset">
                <div className={styles.passwordResetText}>パスワードをお忘れの方</div>
              </A>
            </div>
            <div className={styles.separate}>
              <div className={styles.separateLine} /> 
              <div className={styles.separateText}>または</div>
              <div className={styles.separateLine} /> 
            </div>
            <div className={styles.phoneNumberSigninButtonWrap}>
              <A href="/signin/telephone">
                <Button fit >
                  <div className={styles.phoneNumberSigninButton}>
                    <img src="/assets/images/notification/phone.svg" alt="電話" className={styles.buttonIcon} />
                    <div className={styles.buttonText}>かんたん電話ログイン</div>
                  </div>
                </Button>
              </A>
            </div>
          </div>
        </div>
      </Wrap>
      <div className={styles.signUpArea}>
        <div className={styles.signUpText}>まだアカウントを持っていない方</div>
        <div className={styles.signUpButton}>
          <A href="/signup">
            <Button positive fit>新しく登録する</Button>
          </A>
        </div>
      </div>
      <div className={styles.footerLogo}>
        <img src="/assets/images/1stbit.png" alt="logo" width="102" />
      </div>
      <div className={styles.copyright}>
        <div>©︎ 2024 1stbit Co.,Ltd.</div>
      </div>
    </>
  );
}

export { Top };


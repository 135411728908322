import { mdiRefresh } from "@mdi/js";
import Icon from "@mdi/react";
import cx from "clsx";
import { A, navigate } from "hookrouter";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AuthorizedHeader from "../../partials/AuthorizedHeader";
import Button from "../../partials/Button";
import ButtonClose from "../../partials/ButtonClose";
import { CheckUser } from "../../partials/checkUser";
import { ConfirmUserMail } from "../../partials/ConfirmUserMail";
import DashboardSettings from "../../partials/DashboardSettings";
import Footer from "../../partials/Footer";
import { LineConfirmUser } from "../../partials/LineConfirmUser";
import Modal from "../../partials/Modal";
import ProgressIndicator from "../../partials/ProgressIndicator";
import RegistrationGuidePhoneNumberSignIn from "../../partials/RegistrationGuidePhoneNumberSignIn";
import { AlertMessage } from "./alertMessage";
import { DeviceList } from "./deviceList";
import styles from "./index.module.sass";

export const useDidMount = (func) =>
  useEffect(() => {
    func();
  }, []);

let pauseReload = false;
//let localDevices = {};
let savedLastUpdated;

//const fetchDataInterval = 120000;
const fetchDataInterval = 10000;
const routerStatusInterval = 180000;
const routerStatusCheckInterval = 10000;
const uplinkRequestTimeout = 180000;
const uplinkCancelTimeout = 180000;

function Dashboard(props) {
  const [user, setUser] = useState({});
  const [alertTo, setAlertTo] = useState({});
  const [alertMessages, setAlertMessages] = useState([]);
  const [deviceList, setDeviceList] = useState(null);
  const [notificationList, setNotificationList] = useState(null);
  const [purchaseHistoryList, setPurchaseHistoryList] = useState([]);
  const [showBackdrop, setShowBackdrop] = useState(true);
  const [label, setLabel] = useState("通信中");
  const [progress, setProgress] = useState(50);
  const [timer, setTimer] = useState(null);
  const [
    sensorWithUplinkStatusInProgressExists,
    setSensorWithUplinkStatusInProgressExists,
  ] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [formattedLastUpdated, setFormattedLastUpdated] = useState("");
  const [onEditing, setOnEditing] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [showNetworkAlert, setShowNetworkAlert] = useState(false);
  const [
    isShowingSupportForRefreshingModal,
    setShowingSupportForRefreshingModal,
  ] = useState(false);
  const [routerList, setRouterList] = useState([]);
  const [routerStatusChecked, setRouterStatusChecked] = useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [humidityDeficitChecked, setHumidityDeficitChecked] = useState(true);
  const [dewPointChecked, setDewPointChecked] = useState(true);

  const formatLastUpdated = () => {
    if (savedLastUpdated) {
      const time = moment(savedLastUpdated).format("HH:mm");
      const duration = moment.duration(moment().diff(moment(savedLastUpdated)));
      const a_day = 60 * 60 * 24;
      //const days = parseInt(duration.as('seconds'));
      const days = parseInt(parseInt(duration.as("seconds")) / a_day);
      const hours = duration.as("hours");
      // issue 496 のためいったん削除
      //setShouldUpdate((hours > 1.0));
      if (days == 0) {
        return `今日 ${time}`;
      } else if (days == 1) {
        return `昨日 ${time}`;
      } else {
        return `${days}日前 ${time}`;
      }
    }
  };

  const requestUplink = async (args) => {
    openUplinkModal();
    setSensorWithUplinkStatusInProgressExists(true);
    const session = await props.Auth.currentSession();
    let devices = [];
    if (args.devices) {
      devices = args.devices;
    }
    const uplinkData = await props.API.post("AlertList", "/uplink_request", {
      headers: { Authorization: session.idToken.jwtToken },
      body: {
        action: "uplink_request",
        devices: devices,
      },
    });
    await startCheckRouterStatus();
    setLastUpdated(parseInt(uplinkData.lastUpdated));
    props.EE.emit("startRequestUplink", { devices: devices });
    //setTimeout(() => {
    //  props.EE.emit('timeoutRequestUplink', {devices: localDevices});
    //}, uplinkRequestTimeout);
  };

  const cancelUplink = async (args) => {
    openCancelModal();
    setSensorWithUplinkStatusInProgressExists(false);
    const session = await props.Auth.currentSession();
    let devices = [];
    if (args.devices) {
      devices = args.devices;
    }
    const uplinkData = await props.API.post("AlertList", "/uplink_request", {
      headers: { Authorization: session.idToken.jwtToken },
      body: {
        action: "uplink_cancel",
        devices: devices,
      },
    });
    setLastUpdated(parseInt(uplinkData.lastUpdated));
    props.EE.emit("cancelRequestUplink", { devices: devices });
  };

  const updateHumidityDeficitChecked = async () => {
    let checked = !humidityDeficitChecked
    setHumidityDeficitChecked(checked);
    localStorage.setItem("humidityDeficitChecked", checked);
  };

  const updateDewPointChecked = async () => {
    let checked = !dewPointChecked
    setDewPointChecked(checked);
    localStorage.setItem("dewPointChecked", checked);
  };

  const onload = async () => {
    let user = await CheckUser(props.Auth);
    setProgress(50);
    let humidityDeficitCheckedValue = localStorage.getItem("humidityDeficitChecked");
    if (humidityDeficitCheckedValue != null) {
      setHumidityDeficitChecked(humidityDeficitCheckedValue === "true");
    }
    let dewPointCheckedValue = localStorage.getItem("dewPointChecked");
    if (dewPointCheckedValue != null) {
      setDewPointChecked(dewPointCheckedValue === "true");
    }
    if (user) {
      const url = new URL(window.location.href);
      const query = new URLSearchParams(url.searchParams);
      if (query.get("token")) {
        await LineConfirmUser({
          token: query.get("token"),
          Auth: props.Auth,
          API: props.API,
        });
      }
      if (query.get("mail")) {
        await ConfirmUserMail({
          token: query.get("mail"),
          Auth: props.Auth,
          API: props.API,
        });
      }
      setUser(user);
      fetchAlertList();
      fetchNotificationList();
      setProgress(60);
      const session = await props.Auth.currentSession();
      setProgress(70);
      setProgress(80);
      await fetchDeviceList();
      await fetchRouterStatus();
      await fetchPurchaseHistoryList();
      setProgress(90);
    } else {
      navigate("/", false);
    }
  };

  const fetchAlertList = async () => {
    const session = await props.Auth.currentSession();
    const list = await props.API.get("StatusMessages", "/status?limit=true", {
      headers: { Authorization: session.idToken.jwtToken },
    });
    setAlertMessages(list.Items);
  };

  const fetchDeviceList = async () => {
    try {
      setShowNetworkAlert(false);
      const session = await props.Auth.currentSession();
      const devices = await props.API.get("DeviceList", "/devices", {
        headers: { Authorization: session.idToken.jwtToken },
      });
      //await Promise.all(devices.devices.map(async(d) => {
      //  localDevices[d.device_id] = d.value.time;
      //  if (d.value.time != null) {
      //    if (localDevices[d.device_id] != d.value.time) {
      //      props.EE.emit('deviceListUpdated', {device_id: d.device_id});
      //      //localDevices[d.device_id] = null;
      //    }
      //  }
      //}));
      if (devices.devices) {
        const inProgress = devices.devices.some((d) => {
          return d.uplink_status === "in-progress";
        });
        setUpdateInProgress(inProgress);
      }
      setDeviceList(devices);

      // inProgess状態のセンサーがない場合は「センサーの値を更新ボタン」を使用できるようにする
      setSensorWithUplinkStatusInProgressExists(
        devices.devices.some((device) => device.uplink_status === "in-progress")
      );
    } catch (e) {
      // alert and refresh
      setShowNetworkAlert(true);
    }
  };

  const fetchNotificationList = async () => {
    const session = await props.Auth.currentSession();
    const list = await props.API.get("AlertList", "/alert_list", {
      headers: { Authorization: session.idToken.jwtToken },
    });
    setNotificationList(list);
  };

  const fetchPurchaseHistoryList = async () => {
    const session = await props.Auth.currentSession();
    const list = await props.API.get("PurchaseHistory", "/purchase", {
      headers: { Authorization: session.idToken.jwtToken },
    });
    setPurchaseHistoryList(list);
  };

  const scanDevice = async () => {
    const session = await props.Auth.currentSession();
    const res = await props.API.post("RouterStatus", "/authorizedController", {
      headers: { Authorization: session.idToken.jwtToken },
      body: {
        handler: "scanDeviceHandler",
        action: "index",
      },
    });
  };

  const fetchRouterStatus = async () => {
    const session = await props.Auth.currentSession();
    const res = await props.API.post("RouterStatus", "/authorizedController", {
      headers: { Authorization: session.idToken.jwtToken },
      body: {
        handler: "checkAliveHandler",
        action: "getStatus",
      },
    });
    setRouterStatusChecked(res);
  };

  const startCheckRouterStatus = async () => {
    const session = await props.Auth.currentSession();
    const res = await props.API.post("RouterStatus", "/authorizedController", {
      headers: { Authorization: session.idToken.jwtToken },
      body: {
        handler: "checkAliveHandler",
        action: "index",
      },
    });
    setTimeout(() => {
      setRouterStatusChecked(true);
    }, 30000);
  };

  const fetchData = async () => {
    if (pauseReload) {
      return;
    }
    await fetchDeviceList();
    await fetchNotificationList();
    setFormattedLastUpdated(formatLastUpdated());
  };

  const stopReload = () => {
    setOnEditing(true);
  };

  const startReload = () => {
    setOnEditing(false);
  };

  // Uplinkの状態表示
  const [isShowUplinkModal, setIsUplinkModal] = useState(false);

  const openUplinkModal = () => {
    setIsUplinkModal(true);
    setTimeout(() => {
      setIsUplinkModal(false);
    }, uplinkRequestTimeout);
  };

  // Uplinkキャンセルの状態表示
  const [isShowCancelModal, setIsCancelModal] = useState(false);

  const openCancelModal = () => {
    setIsCancelModal(true);
    setTimeout(() => {
      setIsCancelModal(false);
    }, uplinkCancelTimeout);
  };

  useEffect(() => {
    onload();
    //scanDevice();
    props.EE.addListener("stopreload", stopReload);
    props.EE.addListener("startreload", startReload);
    const localTimer = setInterval(fetchData, fetchDataInterval);
    //const routerTimer = setInterval(startCheckRouterStatus, routerStatusInterval);
    const routerStatusTimer = setInterval(
      fetchRouterStatus,
      routerStatusCheckInterval
    );
    return () => {
      props.EE.removeListener("stopreload", stopReload);
      props.EE.removeListener("startreload", startReload);
      clearInterval(localTimer);
      //clearInterval(routerTimer);
      clearInterval(routerStatusTimer);
    };
  }, []);

  useEffect(() => {
    if (deviceList && deviceList.routers && deviceList.routers.length == 0) {
      setShowBackdrop(false);
    }
  }, [deviceList]);

  useEffect(() => {
    pauseReload = onEditing;
  }, [onEditing]);

  useEffect(() => {
    if (lastUpdated != null) {
      savedLastUpdated = lastUpdated;
      setFormattedLastUpdated(formatLastUpdated());
    }
  }, [lastUpdated]);

  return (
    <>
      {/* ヘッダー */}
      <AuthorizedHeader current="home" user={user} simple />

      {/* Uplink中のヘッダー通知 */}
      {isShowUplinkModal && (
        <div className={styles.uplink}>
          <div className={styles.uplinkTitle}>
            センサーの値の取得を開始しました。
          </div>
        </div>
      )}

      {/* Uplinkキャンセルのヘッダー通知 */}
      {isShowCancelModal && (
        <div className={styles.cancel}>
          <div className={styles.cancelTitle}>
            子機の状態をリセットしています。
          </div>
        </div>
      )}

      {alertMessages.length > 0 && (
        <div className={styles.notification}>
          {/* 通知表示 */}
          {alertMessages.map((alertMessage) => {
            return (
              <AlertMessage
                {...props}
                alertMessage={alertMessage}
                key={alertMessage.created_at}
                header={true}
              />
            );
          })}
        </div>
      )}

      {showBackdrop && (
        <div className={styles.loading}>
          <ProgressIndicator gutter />
        </div>
      )}

      {alertTo && (
        <>
          {/* デバイス未購入 */}
          {deviceList &&
            deviceList.routers &&
            deviceList.routers.length == 0 &&
            purchaseHistoryList.length == 0 && (
              <div className={styles.noDevices}>
                <div className={styles.noDevicesImage}>
                  <img src="/assets/images/blank-devices.png" width={205} />
                </div>
                <div className={styles.noDevicesTitle}>
                  てるちゃんへようこそ
                </div>
                <div className={styles.noDevicesText}>
                  センサー機器をお持ちでない方は、はじめにご購入ください
                </div>
                <div className={styles.noDevicesAction}>
                  <A href="/shop/items">
                    <Button size="large" primary>
                      購入する
                    </Button>
                  </A>
                </div>
                {/*
          <div className={styles.phoneNumberSignin}>
            <RegistrationGuidePhoneNumberSignIn />
          </div>
          */}
              </div>
            )}

          {/* デバイス購入済み（登録数は0） */}
          {deviceList &&
            deviceList.routers &&
            deviceList.routers.length == 0 &&
            purchaseHistoryList.length > 0 && (
              <div className={styles.noDevicesDelivery}>
                <div className={styles.noDevicesDeliveryImage}>
                  <img src="/assets/images/delivery.png" width={150} />
                </div>
                <div className={styles.noDevicesDeliveryTitle}>
                  センサー機器を配送中
                </div>
                <div className={styles.noDevicesDeliveryText}>
                  お届けまで、ご注文から2週間程かかる場合があります
                </div>
                <div className={styles.phoneNumberSignin}>
                  <RegistrationGuidePhoneNumberSignIn />
                </div>
              </div>
            )}

          {/* 通常 */}
          {deviceList && deviceList.routers && deviceList.routers.length > 0 && (
            <div className={styles.wrap}>
              <div className={styles.deviceStatusWrap}>
                <div className={cx(styles.deviceStatusArea, styles.current)}>
                  <button
                    onClick={requestUplink}
                    className={cx(styles.refreshButton, styles.currentButton)}
                    disabled={sensorWithUplinkStatusInProgressExists}
                  >
                    <div className={styles.refreshIcon}>
                      <Icon size={1} path={mdiRefresh} />
                    </div>
                    <div className={styles.refreshText}>センサーの値を更新</div>
                  </button>
                  <div
                    onClick={() => setShowingSupportForRefreshingModal(true)}
                    className={styles.refreshMethod}
                  >
                    <img
                      src="/assets/images/icon-question.svg"
                      alt="はてなアイコン"
                    />
                    <div className={styles.refreshMethodText}>更新の使い方</div>
                  </div>
                </div>
              </div>
              <DashboardSettings humidityDeficitChecked={humidityDeficitChecked} dewPointChecked={dewPointChecked} onHumidityDeficitClick={updateHumidityDeficitChecked} onDewPointClick={updateDewPointChecked} />
              <DeviceList
                requestUplink={requestUplink}
                cancelUplink={cancelUplink}
                updateInProgress={updateInProgress}
                reload={fetchDeviceList}
                {...props}
                list={deviceList}
                routerStatusChecked={routerStatusChecked}
                routerList={routerList}
                alertTo={alertTo}
                setShowBackdrop={setShowBackdrop}
                setLabel={setLabel}
                setProgress={setProgress}
                humidityDeficitChecked={humidityDeficitChecked}
                dewPointChecked={dewPointChecked}
              />
            </div>
          )}
          {/* 更新の使い方モーダル */}
          <Modal show={isShowingSupportForRefreshingModal}>
            <div className={styles.viewWrap}>
              <div className={styles.viewHeader}>
                <div className={styles.left}>
                  <ButtonClose
                    onClick={() => setShowingSupportForRefreshingModal(false)}
                  />
                </div>
              </div>
              <div className={styles.viewBody}>
                <img
                  src="/assets/images/refresh-device.png"
                  alt="センサー"
                  className={styles.supportForRefreshingImage}
                />
                <div className={styles.supportForRefreshingTitle}>
                  センサーの数値は、赤枠で囲んだ時刻の値です。
                </div>
                <div className={styles.supportForRefreshingText}>
                  更新すると、現在のセンサー値を画面に表示できます。
                </div>
                <div className={styles.supportForRefreshingLists}>
                  <div className={styles.supportForRefreshingList}>
                    <div className={styles.supportForRefreshingListcount}>
                      1
                    </div>
                    <div className={styles.supportForRefreshingListText}>
                      「更新ボタン」をタップします。
                    </div>
                  </div>
                  <div className={styles.supportForRefreshingList}>
                    <div className={styles.supportForRefreshingListcount}>
                      2
                    </div>
                    <div className={styles.supportForRefreshingListText}>
                      データを取得するため、1〜10分ほど待ちます。
                    </div>
                  </div>
                  <div className={styles.supportForRefreshingList}>
                    <div className={styles.supportForRefreshingListcount}>
                      3
                    </div>
                    <div className={styles.supportForRefreshingListText}>
                      センサーの数値が新しくなります。現在の時刻になってることを確認してください。
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
      <Footer />
    </>
  );
}

export default Dashboard;
